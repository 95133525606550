import React from 'react';

const ListServCmp = () => (
  <div className='h-16 my-5 mt-10 mb-12 text-center dark-blue'>
    <form
      method='post'
      action='https://gaggle.email/join/langino-law-pllc-law-firm-owners@gaggle.email'
    >
      <div className='mx-auto lg:w-1/2 md:w-2/3'>
        <div className='flex flex-wrap -m-2'>
          <div className='w-full p-2'>
            <input
              className='w-full px-4 py-2 text-base bg-gray-100 border border-gray-400 rounded focus:outline-none focus:border-secondary'
              name='name'
              type='text'
              placeholder='Name'
              required
            />
          </div>
          <div className='w-full p-2'>
            <input
              className='w-full px-4 py-2 text-base bg-gray-100 border border-gray-400 rounded focus:outline-none focus:border-secondary'
              name='email'
              type='email'
              placeholder='Email Address'
              required
            />
          </div>
          <div className='w-full p-2'>
            <button className='px-4 py-2 ml-6 text-white rounded shadow-sm sm:relative sm:top-4 hover:no-underline bg-primary hover:bg-secondary'>
              Join Group
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
);

export default ListServCmp;
