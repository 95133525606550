import React from 'react';

import Layout from '../components/layout/Layout';
import Seo from '../components/layout/Seo';
import ListServCmp from '../components/ListServCmp';

const ListServPage = () => (
  <Layout>
    <Seo title='Contact Us' />

    <div className='py-12'>
      <div className='container py-6'>
        <section className='text-gray-700 body-font'>
          <div className='container px-5 mx-auto'>
            <div className='flex flex-col w-full mb-6 text-center'>
              <h1 className='py-4 text-center font-slim text-primary'>
                Join Langino Law's Email Group
              </h1>
              <div className='flex flex-col items-center justify-center md:flex-row'>
                <p className='mx-auto text-base leading-relaxed text-left lg:w-2/3'>
                  Since opening my own law firm in 2022, I am often asked how I
                  did it. As the child of a middle-class family, the first
                  lawyer in my family, and the first business owner, I had a lot
                  to learn and I'm happy to share that with other attorneys.
                  This private group is for new law firm owners only and acts as
                  a trusted place to share insights, request feedback, seek
                  advice, and share experiences. If you are interested in
                  joining the group, please fill out the form below.
                </p>
              </div>
            </div>

            <ListServCmp />
          </div>
        </section>
      </div>
    </div>
  </Layout>
);

export default ListServPage;
